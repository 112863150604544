import React, { useState } from 'react';
import { TextField, MenuItem } from '@mui/material';


export default function OtherDropDown(props) {
  const [showOther, setShowOther] = useState(false)

  const onDropDownChange = (e) => {
    const isOther = e.target.value === 'Other'
    setShowOther(isOther)
    if (!isOther)
        props.onChange(e)
  }

  const onTextChange = (e) => {
    props.onChange(e)
  }
  

  return (
      <span className="otherDropDown">
          { !showOther ? 
                <TextField select {...props} value={props.value || ''} onChange={onDropDownChange}>           
                    {props.children}
                    <MenuItem value="Other">Other</MenuItem>     
                </TextField>
          :
              <span className="other-dropdown-text-container">
                  <TextField {...props} className="other-dropdown-text" type="text" name={props.name} value={props.value} label={props.label} onChange={onTextChange} />
                  <i className="fa fa-window-close" onClick={(e) => setShowOther(false)}></i>
              </span>
          }
      </span>
  )
}
