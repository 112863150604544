import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Icon } from '@mui/material';

export default function Tutorial(props) {
  return (
    <Dialog id="tutorial-dialog" fullWidth={true} maxWidth="lg" open={props.open} onClose={props.onClose} aria-labelledby="simple-dialog-title">
          <DialogTitle id="dialog-title" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Workflow for creating a Tap App Student Assessment
            <Icon className="fa fa-times" onClick={props.onClose} sx={{ ml: 'auto' }}></Icon>
          </DialogTitle>

          <div className="flex-row center">
            <div className="flex-col">
              The primary goal of all school safety efforts is to prevent harm to others, especially an active assailant or mass casualty attack. Effective threat management is the single greatest opportunity to prevent a school attack. Threat management uses a methodology that identifies a threat or other concerning behavior that may lead to violence toward others and disrupts that pathway to violence.
            </div>
          </div>
          <div className="flex-row center">
            With that, schools present a unique opportunity and challenge for such efforts.  The Marjory Stoneman Douglas Commission (MSD Commission) created in the horrific aftermath of the Parkland High School shooting has been at the forefront of statutory and policy recommendations in conjunction with the FLDOE’s Office of Safe Schools to make schools safer.
          </div>

          <img src="/img/Threat Management Workflow.jpg"></img>

          <div className="flex-row center">
            <div className="flex-col">
              <a href="/files/Threat Management Manual.pdf" download>Download Manual</a>
            </div>
          </div>

          <div className="flex-row section center">
            <div className="flex-col">
              <span className="subtle">Once closed this information can be found under the Learning Center</span>
            </div>
          </div>
          <br/>

    </Dialog> 
  )
}