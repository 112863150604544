import React, { useContext } from 'react';
import * as Sentry from '@sentry/browser';
import PropTypes from "prop-types";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { Icon, Button } from '@mui/material';

import Carousel from 'react-material-ui-carousel'
import AppContext from '../context/app'


File.propTypes = {
  assetNames: PropTypes.array,
  onDownload: PropTypes.func,
  imageSource: PropTypes.func
};

export default function File(props) {
  const context = useContext(AppContext)

  const handleDownloadFile = () => {
    try {
        props.onDownload()
    }
    catch (err) {
      console.log(err)
      context.toastError('Downloading')
      Sentry.captureException(err);
    }
  }

  return (
    <div className="fileviewer">
      <Dialog className="fileviewer-dialog" fullWidth={true} open={props.open} onClose={props.onClose} aria-labelledby="simple-dialog-title">
          { props.title ?
            <DialogTitle id="dialog-title">{props.title}</DialogTitle>
          : null }
          <Carousel className="carousel" autoPlay={false} index={props.index}>
            { props.files.map( (file, index) => {
              return(
                <div className="uploaded-file-container" key={index}>
                  { file.isImage ?
                    <img 
                      src={props.imageSource(file)}
                      className="uploaded-file"
                      alt={ file.notes || file.fileName  }
                      title={file.notes || file.fileName }
                    />
                  :
                    <span 
                      className="fa fa-file uploaded-file uploaded-file-file"
                      alt={ file.notes || file.fileName  }
                      title={file.notes || file.fileName }
                    />
                  }
                  <div className="uploaded-file-caption">
                    {file.notes ||  file.fileName}
                  </div>
                </div>
              )
              }) 
              }
          </Carousel>
          <div className="section download-button">
            <Button variant="outlined" onClick={ handleDownloadFile }>
              <Icon className="fa fa-download"/>Download
            </Button>
          </div>
      </Dialog> 
    </div>
  )
}