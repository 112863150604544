import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import AppContext from '../../../context/app'
import { Paper, Icon, Button, TextField } from '@mui/material';

import * as Sentry from '@sentry/browser';
import moment from 'moment'
import acknowledgeService from '../../../services/acknowledge';

export default function Acknowledge() {
  const context = useContext(AppContext)
  const location = useLocation()
  const [loading, setLoading] = useState(true)
  const [signature, setSignature] = useState()
  const [complete, setComplete] = useState(false)

  let accessToken = (new URLSearchParams(location.search)).get("accessToken")

  const handleAcknowledge = async (event) => {
    event.preventDefault();
    try {
      //needs to be filled out
      await new acknowledgeService().acknowledge(accessToken, signature)
      setComplete(true)
      window.scroll(0,0)
    }
    catch(err){
      console.log(err)
      context.toastError('There was an error acknowledging')
      Sentry.captureException(err);
    }
}

  useEffect( async () => {
    window.scroll(0,0)
    try {
      setLoading(false)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  return (
    <div id='acknowledge'>
        <h2>Acknowledge</h2>

        <Paper className="paper">            
            { complete ?
                <div>
                    Thank you for acknowledging.  You can close the browser.
                </div>
            :
            <section>
            { !loading ?
                <div>
                    <embed src={`/token/api/acknowledgement/getAsset/?accessToken=${accessToken}#zoom=55&toolbar=0&navpanes=0"`} type="application/pdf" width="100%" height="600px" />               

                    <form onSubmit={handleAcknowledge}>
                        <div className="flex-row section">
                            <div className="flex-col signature">
                                <TextField fullWidth={true} label="Signature" type="text" maxLength="100" value={signature || ''} required onChange={(e) => { setSignature(e.target.value)}}/>
                            </div>
                            <div className="flex-col signature-date">
                                {moment().format('MM/DD/YYYY')}
                            </div>
                        </div>
                        <div className="flex-row section">
                          <Button variant="outlined" type="submit">
                            <Icon className="fa fa-save" />Save
                          </Button>
                        </div>
                    </form>
                </div>
                : null }
                </section>
            }
        </Paper>
     </div>
    )
}