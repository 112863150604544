import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Button } from '@mui/material';
import AppContext from '../context/app'

class Banner extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {};
  }


  render () {
    return (
        <div className="banner">
            { this.props.message ?
            <div className={`banner-container ${this.props.severity}`}>            
                { this.props.title ? 
                <div className="title">{this.props.title}</div>
                : null }

                <span className="message" dangerouslySetInnerHTML={{__html: this.props.message}}></span>
                
                { this.props.todo ?            
                    <div>
                        <Link to={this.props.todo.link}>
                          <Button className="todo" variant="outlined">
                            <Icon className="fa fa-arrow-right" /> {this.props.todo.text}
                          </Button>
                        </Link>
                    </div>
                : null }
            </div>
            : null }
        </div>
    )
  }
}


export default Banner
