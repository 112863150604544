import React from 'react'
import AppContext from '../context/app'

class AppError extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { }
  }

  componentDidCatch(err, info) {
    console.log(err)
    this.setState({hasError: true})
  }

  render () {
    if (this.state.hasError)
        return (
            <div id="app-error">
                <div className="error-message">
                    <i className="fa fa-times"></i> Oops, There was a critical error, please <a href="." >reload</a> the application.
                </div>
            </div> 
        )

    return this.props.children; 
  }
}

export default AppError;
