import React, { useState } from 'react';
import { TextField, Checkbox, FormControlLabel } from '@mui/material';


export default function OtherCheckbox(props) {
  const [showOther, setShowOther] = useState(!!props.value)

  return (
        <span>
            <div className="flex-row">
              <div className='flex-col'>
                <FormControlLabel className="otherCheckbox" label="Other" control={<Checkbox checked={showOther} onChange={(e) => { setShowOther(e.target.checked); if (props.checkedOnChange) props.checkedOnChange(e); }}/>} />
              </div>
              <div className='flex-col'>
                { !!showOther ? 
                  <TextField required label="Other" value={props.value || ''} onChange={props.onChange}/> 
                : null  }
              </div>
             </div>
        </span>
    )
}

