export default class institutionService {
  get(institutionId) {
    return fetch(`/api/institution/get?institutionId=${institutionId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then( (response) => {
      if (!response.ok)
          throw new Error('error calling /api/institution/get')

      return response.json();
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getInstitutions() {
    return fetch('/api/institution/getInstitutions', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then( (response) => {
      if (!response.ok)
          throw new Error('error calling /api/institution/getInstitutions')

      return response.json();
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getInstitutionBuildings(institutionId) {
    return fetch(`/api/institution/getInstitutionBuildings?institutionId=${institutionId}`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then( (response) => {
      if (!response.ok)
          throw new Error('error calling /api/institution/getInstitutionBuildings')

      return response.json();
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }
}