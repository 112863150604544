import {get} from './http'


export default class acknowledgeService {
  acknowledge(accessToken, signature) {
    return fetch('/token/api/acknowledgement/acknowledge', {
        method: 'POST',
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify({ accessToken, signature }),
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }).then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response
      }) 
      .catch( (error) => {
        console.error(error);
        throw Error(error);
      })
  }
}