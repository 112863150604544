//parse the query string
const queryParams = (data) => {
    return Object.keys(data)
        .filter(k => { return !!data[k] }  )
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(data[k]))
        .join('&');
}

export function get(url, data, options) {
    //setup Url query string
    if (data)
        url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(data);

    return fetch(url, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
        if (options?.successGet){ 
            if (!response.ok)
                throw Error(`get failed on ${url}`)
            return response.ok
        }
        return response.json() 
    })
    .catch( (error) => {
        console.error(error);
        throw Error(error);
    })
}