import React from 'react'
import AppContext from '../context/app'


class Header extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {  }
  }

  nextStep(e) {
    e.preventDefault()
    window.scroll(0,0)
  }

  render () {
  
    return (
      <header>
        <div className='flex-row wrapper'>
          <span className="flex-col">
            <span className="flex-row">
              <a href={ process.env.REACT_APP_OVERRIDE_BACK_LINK || 'https://login.clpsconsultants.com' } className="link-back-login"><i className="fa fa-angle-left back-login"></i></a>
              <span className='logo logo-row'>
                <a href={ process.env.REACT_APP_OVERRIDE_BACK_LINK || 'https://login.clpsconsultants.com' }>
                  <span className="flex-row">
                    <span className="logo-icon flex-col"><img alt="CLPS logo" src="/img/logoIcon.png"></img></span> 
                    <span className="logo-red flex-col">CLPS</span>
                    <span className="product-title flex-col">ASSESSMENT</span>
                  </span>
                </a>
              </span>
            </span>
          </span>

          <div className='flex-col'>
            { this.context.user ?
              <div className="flex-row">
                <a href={`${process.env.REACT_APP_OVERRIDE_BACK_LINK || 'https://login.clpsconsultants.com'}/learning`}><div className="flex-col need-help">Learning Center</div></a>
                <a href={`${process.env.REACT_APP_OVERRIDE_BACK_LINK || 'https://login.clpsconsultants.com'}/home?help=1`}><div className="flex-col need-help">Need Help?</div></a>
                <a href="https://login.clpsconsultants.com/logout"><div className='flex-col btn-logout'>LOGOUT</div></a>
              </div>
            :
              null
            }
          </div>
      
        </div>

      </header>
    )
  }
}

export default Header;
