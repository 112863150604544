import React from 'react'
import AppContext from '../context/app'

class Menu extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = { showMenu: false }
    this.toggleMenu = this.toggleMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState( { showMenu: false })
    }
  }

  toggleMenu(e) {
    this.setState( { showMenu: !this.state.showMenu })
  }

  hideMenu(e) {
    this.setState( { showMenu: false })
  }

  render () {
    let openerClass = this.state.showMenu ? 'open' : ''

    return (
      <div className="menu" ref={this.setWrapperRef}>
        <i className={`opener fa fa-ellipsis-v ${openerClass}`} onClick={this.toggleMenu}></i>
        { this.state.showMenu ?
            <div onClick={this.hideMenu} className="menu-items">
                {this.props.children}
            </div>
        : null }
      </div>
    )
  }
}

export default Menu
