import React, { useEffect, useContext, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { Paper, Icon, Button, TextField, Checkbox, MenuItem, FormControl, FormGroup, RadioGroup, Radio, FormControlLabel} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import moment from 'moment'
import AppContext from '../../context/app'
import OtherCheckbox from '../../components/OtherCheckbox';
import threatAssessmentService from '../../services/threatAssessment';
import Loader from '../../components/Loader';


export default function Resolve(props) {
  const context = useContext(AppContext)
  const { threatAssessmentId } = useParams()
  const [originalResolutionClassification, setOriginalResolutionClassification] = useState()
  const [assessment, setAssessment] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    null
  );
  const [assessmentModel, setAssessmentModel] = useState(null)
  const [loading, setLoading] = useState(true)
  
  useEffect( async () => {
    window.scroll(0,0)

    try {
      //load all data needed
      let threatAssessment = await new threatAssessmentService().get(threatAssessmentId)
      if (threatAssessment && threatAssessment.error){
        setLoading(false)
        return
      }

      if (threatAssessment.resolutionFollowUpDate)
        threatAssessment.resolutionFollowUpDate = moment(threatAssessment.resolutionFollowUpDate)

      if (threatAssessment.resolveQuestions.find( rq => rq.type === 'summaryOfObservations' ) ) {
        threatAssessment.resolveQuestions.find( rq => rq.type === 'summaryOfObservations').sections.flatMap( s => s.questions).forEach( (resolutionQuestion) => {
          //auto-default answers
          if (resolutionQuestion.config?.autoAnswerSameAsQuestionId) {
            threatAssessment.interviewers.forEach( (otherInterview) => {
              let otherInterviewQuestion = otherInterview.questionSections.flatMap(s => s.questions).find( (question) => { return question.threatAssessmentInterviewQuestionId === resolutionQuestion.config.autoAnswerSameAsQuestionId } )
              if (otherInterviewQuestion && otherInterviewQuestion.answers) {
                let otherInterviewAnswers = otherInterviewQuestion.answers
                otherInterviewAnswers.forEach((otherAnswer) => {
                  //get the choice with the same text
                  let sameChoice = resolutionQuestion.answerChoices.find( (choice) => { return choice.text === otherAnswer.text || (!!choice.isYes && !!otherAnswer.isYes) } ) 
                  // //set the answer
                  // let answer = resolutionQuestion.answer.find( (answer) => { return resolutionQuestion.threatAssessmentInterviewQuestionId === answer.threatAssessmentInterviewQuestionId } )
                  resolutionQuestion.answer = sameChoice
                  if (otherInterviewQuestion.comments && otherInterviewQuestion.comments.comment) {
                    resolutionQuestion.comments ||= { comment: ''}
                    resolutionQuestion.comments.comment = `${resolutionQuestion.comments.comment}${otherInterviewQuestion.comments.comment}\n`
                  }
                })
              }
            })
          }
        })
      }
      setOriginalResolutionClassification(threatAssessment.resolutionClassification)
      setAssessment(threatAssessment)

      let loadedAssessmentModel = await new threatAssessmentService().getAssessmentModelByAssessmentId(threatAssessment.threatAssessmentId)
      setAssessmentModel(loadedAssessmentModel)
      
      setLoading(false)
    }
    catch(err){
      console.log(err)
      context.toastError('Loading')
      Sentry.captureException(err);
    }
  },[])

  let handleSave = async (event) => {
    event.preventDefault();
    try {
      let questions = assessment.resolveQuestions.flatMap( q => q.sections).flatMap( s => s.questions)
      let resolution = { threatAssessmentId, resolutionClassification: assessment.resolutionClassification, threatAssessmentResolutionAnswers: questions.flatMap( q => q.answer).filter(a => !!a), threatAssessmentResolutionComments: questions.flatMap( (q) => { return { threatAssessmentInterviewQuestionId: q.threatAssessmentInterviewQuestionId, comment: q.comments?.comment }}).filter( c => c.comment), threatAssessmentResolutionBehaviorPlanCreationParticipants: assessment.behaviorPlanParticipants.filter( bpp => bpp.comment), threatAssessmentResolutionActionPlans: assessment.actionPlan.flatMap(ap => ap.action), resolutionNotes: assessment.resolutionNotes, resolutionFollowUpDate: assessment.resolutionFollowUpDate? assessment.resolutionFollowUpDate.format('YYYY-MM-DD') : null, resolutionClassificationNotes: assessment.resolutionClassificationNotes };
      // console.log(resolution)
      await new threatAssessmentService().resolve(resolution)
      props.history.push({pathname: `/assessment/detail/${threatAssessmentId}` });
      context.toastSuccess('Action Plan Saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Action Plan could not be saved')
      Sentry.captureException(err);
    }
  }

  let actionPlanOtherComponent = (assessment) => {
    let otherIndex = assessment.actionPlan.findIndex( ap => !(Object.keys(ap).length) || !(assessment.config.actionPlan.find( cap => cap.action === ap.action )))
    let otherValue = assessment.actionPlan[otherIndex]?.action
    return(
      <OtherCheckbox maxLength="100" value={otherValue || '' } checkedOnChange={(e) => { if (e.target.checked) assessment.actionPlan.push({}); else { assessment.actionPlan.splice(otherIndex,1)} setAssessment(assessment)}} onChange={(e) => { assessment.actionPlan[otherIndex].action = e.target.value; setAssessment(assessment) }} ></OtherCheckbox>
    )
  }

  let behaviorParticipantsOtherComponent = (assessment) => {
      let otherIndex = assessment.behaviorPlanParticipants.findIndex( bp => !(Object.keys(bp).length) || !(assessment.config.behaviorPlanParticipantOptions.find( bppo => bppo === bp.participant )))
      let otherValue = assessment.behaviorPlanParticipants[otherIndex]?.participant
      return(
        <div className="flex-row">
          <label className="section flex-col">
            <OtherCheckbox name="Other" checked={otherIndex >= 0} value={otherValue} checkedOnChange={(e) => { if (e.target.checked) assessment.behaviorPlanParticipants.push({}); else { assessment.behaviorPlanParticipants.splice(otherIndex,1)} setAssessment(assessment)}} onChange={(e) => { assessment.behaviorPlanParticipants[otherIndex].participant = e.target.value; setAssessment(assessment) }}></OtherCheckbox>
          </label>
          { otherIndex >= 0 ?
            <div className="section">
              <TextField maxLength="1200" className="section behaviorPlanParticipant-comment flex-col" placeholder="Comments" value={assessment.behaviorPlanParticipants[otherIndex].comment} onChange={ (e) => {assessment.behaviorPlanParticipants[otherIndex].comment = e.target.value } }/> 
            </div>
          : null }
        </div>
      )
    }


  const handleUpdateResolutionNotes = async (event) => {
    event.preventDefault();
    try {
        let threatAssessment = { threatAssessmentId, resolutionNotes: assessment.resolutionNotes };
        await new threatAssessmentService().update(threatAssessment)
        context.toastSuccess('Notes saved')
    }
    catch(err){
      console.log(err)
      context.toastError('Notes could not save')
      Sentry.captureException(err);
    }
  }

    if(loading)
      return (<Loader/>)

    if (assessment && assessmentModel?.interviewTypes.length && !assessment.interviewers.length && assessment.isOpen)
      return (
        <Paper className="paper">
          Please complete interviews before creating an action plan
        </Paper>
      )

    if (assessment && !assessment.isOpen)
      return ( 
        <div id='threatAssessmentResolve'>
          <Paper className="paper">
              <h2>Case Resolution or Safety Plan</h2>
              <div className='flex-row section'>
                  {assessment.resolutionClassification ?
                      <div className='flex-col'>
                          <label>Classification:</label> {assessment.resolutionClassification}
                      </div>
                  : null }
              </div>
              { assessment.actionPlan.length ?
                  <div className="flex-row section">
                      <div className="flex-col">
                          Action Plan:
                          <div className="actionPlans">
                              {assessment.actionPlan.map( (plan, index) => {
                                  return(
                                      <div key={index}>
                                          {plan.action}
                                      </div>
                                  )
                              })}
                          </div>
                      </div>
                  </div>
              : null }
              <div className='flex-row section'>
                  <div className='flex-col resolution-notes-section'>
                        <TextField multiline maxRows={5}  inputProps={{ maxLength: 8000}}  maxLength="8000" label="Case Resolution or Safety Plan" value={assessment.resolutionNotes || ''}  onChange={ (e) => { assessment.resolutionNotes = e.target.value; setAssessment(assessment) } }/> 

                      { assessment.resolutionFollowUpDate ?
                      <div className='section'>
                          Follow up { moment(assessment.resolutionFollowUpDate).format('MM/DD/YYYY') }
                      </div>
                      : null }
                  </div>
              </div>
              <div className="section">
                  <Button variant="outlined" onClick={handleUpdateResolutionNotes}>
                      <Icon className="fa fa-save" />Save
                  </Button>
              </div>
          </Paper>
        </div>
      )

    return (
      <div id='threatAssessmentResolve'>
            <div>
              { assessment ? 
              <div>  
                  <form onSubmit={handleSave}>

                  { assessment.resolveQuestions.find( ta => ta.type === 'summaryOfObservations' ) ?
                  <Paper className="paper">
                    <h2>Summary of Observations</h2>
                    <div>
                      These items can help assess whether a threat is transient or substantive, but must be considered in the broader context of the situation and other known facts. Regard these items as a checklist to make sure you have considered these aspects of the threat, but they are not to be summed or used as a score.
                    </div>
                    { assessment.resolveQuestions.find( ta => ta.type === 'summaryOfObservations' ).sections.map( (summaryOfObservationsSection, index) => {
                      return (
                        <div key={index}>
                        { summaryOfObservationsSection.section ?
                          <h3>{summaryOfObservationsSection.section}</h3>
                        : null }

                        <ol className="resolutionQuestions">
                        { summaryOfObservationsSection.questions.map( (resolutionQuestion) => {
                          return (
                              <li className="rows interviewQuestion" key={resolutionQuestion.threatAssessmentInterviewQuestionId} >
                                  <div>
                                    {resolutionQuestion.text}
                                  </div>
                                  <div className="resolutionQuestion-subText">
                                      {resolutionQuestion.subText}
                                  </div>
                                  <div>
                                    <FormControl component="fieldset">
                                      <RadioGroup row>
                                      { resolutionQuestion.answerChoices.map( (answerChoice, index) => {
                                          let answer = resolutionQuestion.answer
                                          return (
                                              <FormControlLabel key={index} label={answerChoice.text} control={<Radio required />} checked={ !!(answer?.threatAssessmentInterviewQuestionAnswerChoiceId && answerChoice.threatAssessmentInterviewQuestionAnswerChoiceId === resolutionQuestion.answer.threatAssessmentInterviewQuestionAnswerChoiceId) } type="radio" required onChange={(e) => { resolutionQuestion.answer = {...answerChoice}; setAssessment(assessment) }}/>
                                          )
                                        })
                                      }
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                  <div>
                                    <TextField multiline maxRows={5}  inputProps={{ maxLength: 1200}}  maxLength="1200" label="Comments" value={resolutionQuestion.comments ? resolutionQuestion.comments.comment : ''} required={!resolutionQuestion.answer} onChange={(e) => {  resolutionQuestion.comments ||= {}; resolutionQuestion.comments.comment = e.target.value; setAssessment(assessment) }}/> 
                                  </div>
                              </li>
                          )
                        })}
                        </ol>
                        </div>
                      )
                    })}
                  </Paper>
                  : null }

                  { assessment.resolveQuestions.find( ta => ta.type === 'observationsForIntervention' ) ?
                  <Paper className="paper">
                    <h2>Observations Suggesting Need for Intervention</h2>
                    <div className="subText">(optional)</div>
                    <ol className="interventionQuestions">
                    { assessment.resolveQuestions.find( ta => ta.type === 'observationsForIntervention' ).sections[0].questions.map( (resolutionQuestion) => {
                          return (
                              <li className="rows resolutionQuestion" key={resolutionQuestion.threatAssessmentInterviewQuestionId} >
                                  <div>
                                    {resolutionQuestion.text}
                                  </div>
                                  <div>
                                    <FormControl component="fieldset">
                                      <RadioGroup row>
                                      { resolutionQuestion.answerChoices.map( (answerChoice, index) => {
                                          let answer = resolutionQuestion.answer
                                          return (
                                            <FormControlLabel key={index} label={answerChoice.text} control={<Radio/>} checked={ !!(answer?.threatAssessmentInterviewQuestionAnswerChoiceId && answerChoice.threatAssessmentInterviewQuestionAnswerChoiceId === resolutionQuestion.answer.threatAssessmentInterviewQuestionAnswerChoiceId) } type="radio" onChange={(e) => { resolutionQuestion.answer = {...answerChoice}; setAssessment(assessment) }}/>
                                          )
                                        })
                                      }
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                  <div>
                                    <TextField multiline maxRows={5}  inputProps={{ maxLength: 1200}}  maxLength="1200" label="Comments" value={resolutionQuestion.comments ? resolutionQuestion.comments.comment : ''} onChange={(e) => {  resolutionQuestion.comments ||= {}; resolutionQuestion.comments.comment = e.target.value; setAssessment(assessment) }}/> 
                                  </div>
                              </li>
                          )
                      }
                    )}
                    </ol>
                  </Paper>
                  : null }

                  <Paper className="paper">
                  { assessment.config.resolutionClassifications ?
                   <div>
                      <h2>Safety Plan</h2>
                      <div>After the team reviews the information contained in these assessment reports, the initial classification of this case will be:</div>

                      <div className="section">
                        <TextField select required label="Classification" value={assessment.resolutionClassification || ''} onChange={(e) => { assessment.resolutionClassification = e.target.value; setAssessment(assessment) }} >
                            { assessment.config.resolutionClassifications.map( (resolutionClassification, index) => {
                              return (<MenuItem key={index} value={resolutionClassification}>{resolutionClassification}</MenuItem>)
                            })}
                        </TextField>
                      </div>

                      { assessment.resolutionClassification && originalResolutionClassification &&  assessment.resolutionClassification !== originalResolutionClassification  ?
                        <div className='section'>
                          <TextField multiline maxRows={5}  inputProps={{ maxLength: 500}}  maxLength="500" label="Reason for change of Classification" value={assessment.resolutionClassificationNotes} required onChange={(e) => { assessment.resolutionClassificationNotes = e.target.value; setAssessment(assessment) }}/> 
                        </div>
                      : null }

                      { assessment.config.resolutionClassifications.find( rc => rc === 'Substantive-Serious Threat' ) ?
                        <section>
                          <div className="section">If classified as <span className="warn">Substantive-Serious Threat</span> or above, additional <u>mental health assessments</u> are required.  It is recommended that additional mental health assessments be performed by licensed mental health providers.</div>   
                          <br/>
                          <div>For serious cases, the school can access a variety of supplemental assessment tools, including the Virginia Student Threat Assessment Guidelines &copy;.  Click here to download: <a download href="/files/Virginia Student Threat Assessment Guidelines.pdf">Virginia Student Threat Assessment Guidelines &copy;</a></div>
                          <br/>
                          <div>After additional mental health assessments are performed, a comprehensive threat management plan should be developed.</div>
                          <br/>
                        </section>
                      : null }
                    </div>
                  : null }


                  { assessment.config.actionPlan ? 
                    <div>
                      <div>The short-term threat management safety plan includes (check all that apply)</div>
                      <FormGroup>
                        { assessment.config.actionPlan.map( (actionPlanOption, index) => {
                          return (
                            <FormControlLabel className='actionPlanOption' key={index} label={actionPlanOption.action} control={<Checkbox value={actionPlanOption.action} checked={!!assessment.actionPlan.find( a => a.action === actionPlanOption.action) } onChange={(e) => { if (e.target.checked) { assessment.actionPlan.push({ action: actionPlanOption.action}) } else { assessment.actionPlan.splice(assessment.actionPlan.findIndex( a => a.action === actionPlanOption.action), 1) } setAssessment(assessment) }}/>} />
                          )
                        })}
                      </FormGroup>                    
                      <span className="flex-row">
                        { actionPlanOtherComponent(assessment) }
                      </span>
                    </div>
                  : null }

                 
                  <div>
                      { assessment.typeName === 'Threat Assessment' ?
                        <div>
                          This section can be used to describe the plan for any case and should be completed as Step 5 in cases of a very serious substantive threat.
                          <ol>
                            <li>Describe how case was resolved, including any plan for further actions. List persons responsible for each component of plan.</li>
                            <li>Describe current status of plan and any revisions. List persons responsible for each component of revised plan.</li>
                          </ol>    
                        </div>
                        : null }
                        <TextField multiline maxRows={5} InputProps={{inputProps: { maxLength: 2000}}} label="Case Resolution or Safety Plan and Follow Up" value={assessment.resolutionNotes || ''} onChange={(e) => { assessment.resolutionNotes = e.target.value; setAssessment(assessment) }}/> 
                        
                        <div className='flex-row section'>
                          <div className='flex-col'>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker disablePast={true} label="Remind Me to Follow Up" value={assessment.resolutionFollowUpDate || null} onChange={(e) => { assessment.resolutionFollowUpDate = e; setAssessment(assessment) }} renderInput={(props) => <TextField {...props} />}/>
                            </LocalizationProvider>
                          </div>
                        </div>
                  </div>
                </Paper>
 

                    { assessment.typeName === 'Threat Assessment' && assessment.resolveQuestions.find( ta => ta.type === 'behaviorInterventionPlan' ) ?
                     <Paper className="paper">
                      <h2>Behavior Intervention Plan</h2>
                      <div className="subText">(optional)</div>

                      { assessment.resolveQuestions.find( ta => ta.type === 'behaviorInterventionPlan' ).sections.map( (behaviorInterventionPlanQuestionSection, index) => {
                        return (
                          <div key={index}>
                          { behaviorInterventionPlanQuestionSection.section ?
                            <h3>{behaviorInterventionPlanQuestionSection.section}</h3>
                          : null }

                          <ol className="interventionQuestions">
                          { behaviorInterventionPlanQuestionSection.questions.map( (resolutionQuestion) => {
                            return (
                                <li className="rows resolutionQuestion" key={resolutionQuestion.threatAssessmentInterviewQuestionId} >
                                    <div>
                                      {resolutionQuestion.text}
                                    </div>
                                    <div className="resolutionQuestion-subText">
                                        {resolutionQuestion.subText}
                                    </div>
                                    <div>
                                      <FormControl component="fieldset">
                                        <RadioGroup row>
                                        { resolutionQuestion.answerChoices.map( (answerChoice, index) => {
                                            let answer = resolutionQuestion.answer
                                            return (
                                              <FormControlLabel key={index} label={answerChoice.text} control={<Radio/>} checked={ !!(answer?.threatAssessmentInterviewQuestionAnswerChoiceId && answerChoice.threatAssessmentInterviewQuestionAnswerChoiceId === resolutionQuestion.answer.threatAssessmentInterviewQuestionAnswerChoiceId) } type="radio" onChange={(e) => { resolutionQuestion.answer = {...answerChoice}; setAssessment(assessment) }}/>
                                            )
                                          })
                                        }
                                        </RadioGroup>
                                      </FormControl>
                                  </div>
                                  <div>
                                    <TextField multiline maxRows={5}  inputProps={{ maxLength: 1200}}  maxLength="1200" label="Comments" value={resolutionQuestion.comments ? resolutionQuestion.comments.comment : ''} onChange={(e) => { resolutionQuestion.comments ||= {}; resolutionQuestion.comments.comment = e.target.value; setAssessment(assessment) }}/> 
                                  </div>
                                </li>
                              )
                            }
                          )}
                          </ol>
                          </div>
                        )
                      })
                      }
                    </Paper> 
                    :null }

                  { assessment.config.behaviorPlanParticipantOptions ?
                  <Paper className="paper">
                    <h3>Participant Part 6: Participants in Plan Development</h3>
                    { assessment.config.behaviorPlanParticipantOptions.map( (behaviorPlanParticipantOption, index) => {
                      return(
                        <div className="section flex-row" key={index}>
                        <label className="behaviorPlanParticipantOption flex-col">
                          <FormControlLabel key={index} label={behaviorPlanParticipantOption} control={<Checkbox value={behaviorPlanParticipantOption} checked={!!assessment.behaviorPlanParticipants.find( bpp => bpp.participant === behaviorPlanParticipantOption ) } onChange={(e) => { if (e.target.checked) assessment.behaviorPlanParticipants.push({ participant: e.target.value}); else {assessment.behaviorPlanParticipants = assessment.behaviorPlanParticipants.filter( bpp => bpp.participant !== e.target.value )} setAssessment(assessment)} }/>} />
                        </label>
                        { assessment.behaviorPlanParticipants.find( bpp => bpp.participant === behaviorPlanParticipantOption ) ?
                          <div className="section">
                            <TextField maxLength="1200" className="behaviorPlanParticipant-comment flex-col" required label="Comments" value={assessment.behaviorPlanParticipants.find( bpp => bpp.participant === behaviorPlanParticipantOption ).comment} onChange={(e) => { assessment.behaviorPlanParticipants.find( bpp => bpp.participant === behaviorPlanParticipantOption).comment = e.target.value; setAssessment(assessment)}}/> 
                          </div>
                        : null }
                      </div>
  
                      )
                    })}

                    { behaviorParticipantsOtherComponent(assessment) }
                  </Paper>
                  : null }

                  <div className="section">
                    <Button variant="outlined" type="submit">
                      <Icon className="fa fa-save" />Save
                    </Button>
                  </div>

                </form>
              </div>
            : null }
          </div>
        </div>
            
    )
}