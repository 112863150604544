export default class userService {
  get() {
    return fetch('/api/user/get', { method: 'GET', mode: 'cors', credentials: 'include' })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      if (response.redirected) {
        window.location.href = response.url;
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getInstitutionBuildingUsers(institutionBuildingId){
    return fetch(`/api/user/getInstitutionBuildingUsers?institutionBuildingId=${institutionBuildingId || ''}`, { 
      method: 'GET', 
      mode: 'cors', 
      credentials: 'include'
    })
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json(); 
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  getSystems(){
    return fetch(`/api/user/getSystems`, { method: 'GET', mode: 'cors', credentials: 'include' })
    .then( (response) => {
      if (!response.ok)
          throw new Error('error calling /api/user/getSystems')

      return response.json();
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }


}