import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@mui/material//Dialog';
import DialogTitle from '@mui/material//DialogTitle';
import { Icon, Button, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {DropzoneArea} from 'material-ui-dropzone'

import AppContext from '../context/app'
import threatAssessmentService from '../services/threatAssessment';
import ActionButtons from './ActionButtons'



export default function NotifyTemplateDialog(props) {
    const context = useContext(AppContext)
  
    const [shareEmail, setShareEmail] = useState(null)
    const [files, setFiles] = useState([])
    const [assetNames, setAssetNames] = useState([])
  
    const bindAssets = async () => {
        let assetNames = await new threatAssessmentService().getAssetNames(+props.assessment.threatAssessmentId, 'notification')
        setAssetNames(assetNames)
    }

    useEffect( async () => {
        bindAssets()
    },[])

    const handleShareCase = async (e) => {
        e.preventDefault();
  
          try {
              await new threatAssessmentService().notify(+props.assessment.threatAssessmentId, shareEmail)
              setShareEmail(null)
              props.closeDialog()
              context.toastSuccess('Notification sent')
          }
          catch(err){
              console.log(err)
              context.toastError('Notification could not be sent')
          }
    }

    const handleDownloadResource = (resource) => {
        window.open(`/files/${resource}`)
    }

    const handleDownloadFile = (assetId) => {
        new threatAssessmentService().downloadAsset(+assetId, 'notification')
      }    
    
    const handleDeleteAsset = async (assetId) => {
          try{
            await new threatAssessmentService().deleteAsset(+assetId )
            bindAssets()
            context.toastSuccess('File deleted')
        }
        catch(err){
            console.log(err)
            context.toastError('File could not be deleted')
            Sentry.captureException(err);
        }
    }


    const handleUploadAsset = async () => {
        try {
          const data = new FormData();
          data.append('file', files[0]);
    
          await new threatAssessmentService().uploadFile(+props.assessment.threatAssessmentId, data, 'notification')
          bindAssets()
    
          //reset for next image
          setFiles([])
        }
        catch(err){
          console.log(err)
          context.toastError('Uploading file')
        }
      }

    return (
        <Dialog maxWidth='md' fullWidth={true} className="notifyTemplateDialog" open={props.open} onClose={(e) => props.closeDialog()} aria-labelledby="simple-dialog-title">
            <DialogTitle id="dialog-title">Notify and Request Acknowledgement:</DialogTitle>
            <div className="share-dialog">

                <div className="flex-row templates">
                    { props.assessment.ownerAccess && props.assessment.isOpen && props.assessment.config.resources ? 
                            <div className="flex-col">
                                <ActionButtons icon="book" variant="outlined" title="Templates">
                                    <MenuList className="menu-list" >
                                        { props.assessment.config.resources.filter( r => r.isNotify ).map( (resource, index) => {
                                                return (
                                                    <MenuItem className="menu-item" key={index}>
                                                        <div onClick={(e) => handleDownloadResource(resource.file)} className="resource">
                                                            <Icon className="fa fa-download"/>
                                                            <span className="resource-text">{ `${resource.name}` }</span>
                                                        </div>
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </MenuList>
                                </ActionButtons>                        
                            </div>
                    : null  }
                </div>

                { !assetNames.length ?
                    <div className="dropzonearea">                  
                        <DropzoneArea
                            maxFileSize={30000000}
                            filesLimit={1}
                            onChange={(files) => { setFiles(files) }}
                            onAlert={handleUploadAsset}
                            acceptedFiles={['application/pdf','application/vnd.openxmlformats-officedocument.wordprocessingml.document']}                            
                        />
                    </div>  
                :  
                    <div className="flex-row section">
                        { assetNames.map( (fileName, index) => {
                            return(
                                <div className="flex-col uploaded-file-container" key={index}>
                                    <span 
                                        className="fa fa-file uploaded-file"
                                        alt={ fileName.fileName  }
                                        title={fileName.fileName }
                                        onClick={ (e) => handleDownloadFile(fileName.threatAssessmentAssetId)}
                                    />
                                    <span>
                                        <i className="fa fa-trash delete-image" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteAsset(fileName.threatAssessmentAssetId) }}></i>
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                }

                <form onSubmit={handleShareCase}>
                    <div className="share-dialog-email">
                        <TextField className="shareEmail" label="Email" type="email" required value={shareEmail || ''} onChange={(e) => setShareEmail(e.target.value)}/>
                    </div>

                    <div className="share-button-container">
                        <Button variant="outlined" type="submit">
                            <Icon className="fa fa-envelope" />Notify
                        </Button>
                    </div>
                </form>
                </div>
        </Dialog>
    )
}