import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';

export default function HelpTooltip(props) {
  return (
    <div className="helpToolTip">
      <Tooltip
          placement="top"
          title={
              <React.Fragment>
                  {props.title}
              </React.Fragment>
          }
      >
        <i className="fa fa-question helpicon"></i>
      </Tooltip>
    </div>
  );
}
  