import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AppContext from '../context/app'

const defaultTabs = [
  { name: 'Open Cases', location: '/list', default: true, permissions: ['threatTeam','viewSelfHarm','viewAllThreats'] },
  { name: 'Archived Cases', location: '/archive', permissions: ['threatTeam','viewSelfHarm', 'viewAllThreats'] },
  { name: 'Review Cases', location: '/review', permissions: ['reviewThreats'] },
  { name: 'Analysis', location: '/analyze', permissions: ['viewAllThreats'] }
]

export default function SideNav(props) {
  const context = useContext(AppContext)
  const location = useLocation()

  const [showNav, setShowNav] = useState(null)
  const [tabs, setTabs] = useState([])
  
  const setActiveTab = () => {
    let updatedTabs = [...defaultTabs]
    let defaultTab = updatedTabs.find( t => t.default )
    updatedTabs = updatedTabs.filter(t => !t.permissions || t.permissions.some( p => context.user[p] ))
    updatedTabs.forEach( t => t.active = null )
    let tab = updatedTabs.find( t => location && location.pathname.indexOf(t.location) > -1 )
      if (tab)
        tab.active = true
      else if (defaultTab)
        defaultTab.active = true
      else if (updatedTabs.length === 1)
        updatedTabs[0].active = true
      setTabs(updatedTabs)
  }

  useEffect( () => {
    window.scroll(0,0)
    setActiveTab()
  },[])

  useEffect( () => {
    setActiveTab()
  },[location])

  return (
    <section id="sidebar">
      <section className="desktop">
      { 
        context.user ?
            <div>
              { tabs.map( (tab, index) => {
                return(
                  <div key={index} className="threatAssessment-link">
                    <Link className={`module flex-row ${tab.active ? 'active' : ''}`} to={tab.location}>
                      <div className="linkStyle">{tab.name}</div>
                    </Link>
                  </div>
                )
              })}
            </div>
          : null
          }
      </section>

      <section className="tablet">
        <div>
          { !showNav ?
            <i className="fa fa-bars showNav-bar" onClick={(e) => setShowNav(!showNav)}></i>
          :
            <i className="fa fa-minus showNav-bar" onClick={(e) => setShowNav(!showNav)}></i>
          }
        </div>
      
        { showNav ?
          <div className="mobile-nav">
            { context.user ?
              <div>
                { tabs.map( (tab, index) => {
                  return(
                    <div key={index} className="threatAssessment-link" onClick={(e) => setShowNav(false)}>
                      <Link className={`module flex-row ${tab.active ? 'active' : ''}`} to={tab.location}>
                        <div className="linkStyle">{tab.name}</div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            : null
          }  
          </div>
        : null }
      </section>
    </section>
  )
}