import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader(props) {
  return (
     <div id="loader">
        <div className="load-icon">
          <CircularProgress />
         </div>
     </div>  
    )
}
