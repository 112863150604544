import {get} from '../../http'

export default class assessmentFormService {

  getAnswer(formAnswerId) {
    return get('/api/threatAssessment/form/getAnswer', { formAnswerId })    
  }

  getForms(threatAssessmentId, formId) {
    return get('/api/threatAssessment/form/getForms', { threatAssessmentId, formId })    
  }


  save(form) {
    return fetch('/api/threatAssessment/form/save', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(form),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;  
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }

  update(form) {
    return fetch('/api/threatAssessment/form/update', 
      { method: 'POST', 
        mode: 'cors', 
        credentials: 'include',
        body: JSON.stringify(form),
        json: true,
        headers: {'Accept': 'application/json','Content-Type': 'application/json'}
      }
    )
    .then(response => { 
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.ok;  
    })
    .catch( (error) => {
      console.error(error);
      throw Error(error);
    })
  }
}