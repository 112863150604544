import React, { useEffect, useContext, useState } from 'react';
import { Collapse, Fab } from '@mui/material';
import AddIcon from '@material-ui/icons/Add';
import Loader from '../../../components/Loader'

import HPTMFollowUpItem from './HPTMFollowUpItem'

import AppContext from '../../../context/app'
import formService from '../../../services/assessment/form/form';

const formId = 3

export default function Form(props) {
    const context = useContext(AppContext)
    const [loading, setLoading] = useState(true)
    const [addNew, setAddNew] = useState(false)
    const [forms, setForms] = useState([])
    
    useEffect( async () => {
        let loadedforms = await new formService().getForms(props.threatAssessmentId,formId)
        setForms(loadedforms)

        if (!loadedforms.length)
            setAddNew(true)

        setLoading(false)
    },[])

    if (loading)
        return (<Loader></Loader>)

    return (
    <div id="assessmentForms">
         <h3>Form H</h3>
         { !addNew ?
            <Fab className="no-print" size="small" color="primary" aria-label="add" onClick={()=> { setAddNew(true)}}>
                <AddIcon /> 
            </Fab>
        : null }
        <Collapse in={addNew}>
            <HPTMFollowUpItem {...props} threatAssessmentId={props.threatAssessmentId} />
        </Collapse>

        { forms.map( (form, index) => {
            return (
            <div key={index} className="flex-row">
                <HPTMFollowUpItem {...props} threatAssessmentId={props.threatAssessmentId} formAnswerId={form.formAnswerId} editForm={form} />
            </div>
            )
        })}
    </div>
    )
}